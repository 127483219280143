.sp-noNFTs{
  background-color: rgba(colors(sp-white), 0.1);
  padding: rem(30);
  border-radius: rem(10);
  @include flex(flex, row wrap, null, center, null,null);
  &-icon{
    @include flex(flex, row wrap, null, center, null,null);
    width: rem(150);
    aspect-ratio: 1 / 1;
    opacity: 0.1;
  }
  &-text{
    margin-left: rem(20);
    @include fonts(500, rem(26), rem(33), $sp-fontRegular);
    color: rgba(colors(sp-white), 0.2);
  }
}
@media only screen and (max-width:1536px){
  .sp-noNFTs{
  &-icon{
    width: rem(140);
  }
  &-text{
    @include fonts(500, rem(25), rem(32), $sp-fontRegular);
  }
}
}
@media only screen and (max-width:1199px){
  .sp-noNFTs{
   &-icon{
    width: rem(130);
  }
  &-text{
    @include fonts(500, rem(24), rem(32), $sp-fontRegular);
  }
}
}
@media only screen and (max-width:991px){
    .sp-noNFTs{
   &-icon{
    width: rem(120);
  }
  &-text{
    @include fonts(500, rem(22), rem(30), $sp-fontRegular);
  }
}
}
@media only screen and (max-width:767px){
    .sp-noNFTs{
       padding: rem(20);
  border-radius: rem(5);
   &-icon{
    width: rem(110);
  }
  &-text{
    @include fonts(500, rem(20), rem(28), $sp-fontRegular);
  }
}
}
@media only screen and (max-width:575px){
    .sp-noNFTs{
       padding: rem(15);
  border-radius: rem(5);
   &-icon{
    width: rem(80);
  }
  &-text{
    @include fonts(500, rem(18), rem(24), $sp-fontRegular);
    margin-left: rem(10);
  }
}
}
@media only screen and (max-width:440px){
    .sp-noNFTs{
       padding: rem(15);
      border-radius: rem(5);
       @include flex(flex, row wrap, center, center, null,null);
   &-icon{
    width: rem(80);
  }
  &-text{
    @include fonts(500, rem(18), rem(24), $sp-fontRegular);
    margin-left: rem(0);
    margin-top: rem(10);
    text-align: center;
  }
}
}