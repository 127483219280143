.sp-mint {
  width: 100%;
  &-title {
    @include fonts(400, rem(34), rem(33), $sp-fontRegular);
    display: flex;
    align-items: center;
    margin-bottom: rem(30);
  }
  .sp-form-control {
    height: rem(70);
    padding: rem(15) rem(20);
    @include fonts(400, rem(20), rem(24), $sp-fontRegular);
  }
  &-btn {
    width: auto;
    border-radius: rem(5);
    @include flex(flex, column wrap, center, center, null, null);
    // height: rem(90);
    background-color: colors(sp-primary);
    color: colors(sp-black);
    transition: $transition;
    padding: rem(15) rem(20);
    text-decoration: none;
    @include fonts(500, rem(14), rem(20), $sp-fontMedium);
    &-title {
      @include fonts(600, rem(36), rem(34), $sp-fontBold);
      letter-spacing: rem(2);
    }
    &:hover {
      background-color: colors(sp-hover);
      color: colors(sp-black);
    }
  }
}
.sp-mint-info {
  padding: rem(20);
  background-color: $black;
  border: rem(1) solid rgba(colors(sp-border), 0.5);
  border-radius: rem(5);
  @include flex(flex, row wrap, null, center, null, null);
  gap: rem(10);
  height: 100%;
  &-content {
    flex: 1;
    @include flex(flex, row wrap, null, center, null, null);
    &-title {
      @include fonts(400, rem(20), rem(28), $sp-fontRegular);
      color: colors(sp-primary);
    }
    .sp-form-group-info {
      svg {
        width: rem(18);
        height: rem(18);
      }
    }
  }
  &-pn {
    @include fonts(600, rem(42), rem(28), $sp-fontBold);
    color: colors(sp-white);
  }
}
.sp-radio-buttons {
  @include flex(flex, row wrap, null, center, null, null);
  gap: rem(15);
  .sp-form-group {
    width: auto;
    input[type="radio"] {
      display: none;
    }

    label {
      cursor: pointer;
      position: relative;
      @include fonts(500, rem(24), rem(28), $sp-fontMedium);
      color: colors(sp-white);
      padding-left: rem(45);
    }

    label::before {
      content: "";
      position: absolute;
      width: rem(30);
      height: rem(30);
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 50%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: border-color 400ms ease;
    }

    label::after {
      content: "";
      position: absolute;
      width: rem(26);
      height: rem(26);
      background-color: colors(sp-primary);
      border: 2px solid colors(sp-primary);
      border-radius: 50%;
      top: 50%;
      left: rem(2);
      transform: translateY(-50%) scale(0);
      transition: transform 400ms ease;
    }

    input[type="radio"]:checked + label::before {
      border-color: colors(sp-primary);
    }

    input[type="radio"]:checked + label::after {
      transform: translateY(-50%) scale(0.55);
    }
  }
}
.sp-minttext {
  background-color: rgba(colors(sp-white), 0.1);
  width: 100%;
  height: 100%;
  border-radius: rem(10);
  padding: rem(30);
  color: rgba(colors(sp-primary), 1);

  p {
    @include fonts(500, rem(22), rem(32), $sp-fontRegular);
    margin-bottom: rem(30);
    &:last-child {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 1700px) {
  .sp-mint {
    &-title {
      @include fonts(400, rem(30), rem(34), $sp-fontRegular);
      margin-bottom: rem(25);
    }
  }
}
@media only screen and (max-width: 1600px) {
  .sp-mint {
    &-title {
      @include fonts(400, rem(28), rem(34), $sp-fontRegular);
      margin-bottom: rem(25);
    }
  }
}
@media only screen and (max-width: 1536px) {
  .sp-mint {
    &-title {
      @include fonts(400, rem(27), rem(34), $sp-fontRegular);
      margin-bottom: rem(25);
    }
  }
}
@media only screen and (max-width: 1200px) {
  .sp-mint {
    &-title {
      @include fonts(400, rem(24), rem(34), $sp-fontRegular);
      margin-bottom: rem(25);
    }
  }
}
