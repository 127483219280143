// New Changes ...
.sp-claimpunks {
  width: 100%;
  .sp-card-header {
    border-bottom: none;
    margin-bottom: 0;
  }
  .sp-number-box {
    background-color: rgba($white, 0.1);
    border-radius: rem(10);
    padding: rem(20);
    .sp-starpunks {
      background-color: rgba($white, 0.1);
      border-radius: rem(5);
      padding: rem(20);
      margin-bottom: rem(20);
      &:last-child {
        margin-bottom: 0;
      }
      &-title {
        @include flex(flex, row wrap, space-between, center, null, null);
        margin-bottom: rem(10);
        @include fonts(500, rem(20), rem(26), $sp-fontMedium);
        .sp-total {
          color: rgba($white, 0.5);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .sp-sumofpunks {
      @include flex(flex, row wrap, space-between, center, null, null);
      @include fonts(500, rem(22), rem(30), $sp-fontMedium);
    }
    .sp-btnBox {
      @include flex(flex, row wrap, flex-end, center, null, null);
      .sp-cliam-btn {
        background-color: colors(sp-primary);
        color: $black;
        width: 50%;
        @include fonts(500, rem(20), rem(26), $sp-fontMedium);
        outline: none;
        border: none;
        margin-top: rem(10);
        border-radius: rem(5);
        @include flex(flex, row wrap, center, center, null, null);
        height: rem(70);
        transition: all 0.3s ease;
        &:hover {
          background-color: colors(sp-hover);
        }
      }
    }
  }
  .sp-starpunk-card {
    width: 100%;
    @include flex(flex, row wrap, null, null, null, null);
    background-color: rgba($white, 0.1);
    border-radius: rem(10);
    padding: rem(15);
    .sp-punkImage {
      width: 100%;
      aspect-ratio: 1/1;
    }
    .sp-total-cardpunks {
      color: colors(sp-primary);
      width: 100%;
      @include fonts(400, rem(16), rem(22) $sp-fontRegular);
      margin: rem(10) 0 rem(5);
      &-title {
        @include fonts(500, rem(24), rem(30) $sp-fontMedium);
        color: rgba($white, 1);
        width: 100%;
      }
    }
    .sp-last-claim {
      width: 100%;
      @include fonts(400, rem(16), rem(22) $sp-fontRegular);
      color: rgba($white, 0.5);
      margin-top: rem(10);
      border-bottom: dotted rem(2) rgba($white, 0.5);
      padding-bottom: rem(15);
    }
    .sp-punk-character {
      @include flex(flex, row wrap, space-between, center, null, null);
      width: 100%;
      margin-top: rem(15);
      @include fonts(500, rem(14), rem(20) $sp-fontRegular);
      .sp-punk-title {
        color: rgba($white, 0.5);
      }
    }
    .sp-btn-box {
      width: 100%;
      @include flex(flex, row wrap, flex-end, null, null, null);
      margin-top: rem(15);
      .sp-settingBtn {
        @include flex(flex, row wrap, center, center, null, null);
        border-radius: rem(30);
        outline: none;
        width: rem(110);
        height: rem(50);
        border: none;
        background-color: colors(sp-primary);
        color: colors(sp-black);
        @include fonts(500, rem(18), rem(24) $sp-fontMedium);

        &:hover {
          background-color: colors(sp-hover);
        }
      }
    }
  }
}
.sp-other-nft{
  width:100%;
  .sp-card-header-title{
    margin: rem(30) 0;
  }
}
@media only screen and (max-width:1536px){
  .sp-claimpunks {
  .sp-number-box {
    padding: rem(15);
    .sp-starpunks {
      padding: rem(15);
      margin-bottom: rem(15);
      &-title {
        @include fonts(500, rem(18), rem(24), $sp-fontMedium);
      }
    }
    .sp-sumofpunks {
      @include fonts(500, rem(20), rem(26), $sp-fontMedium);
    }
    .sp-btnBox {
      .sp-cliam-btn {
        @include fonts(500, rem(18), rem(24), $sp-fontMedium);
        height: rem(60);
      }
    }
  }
  .sp-starpunk-card {
    padding: rem(10);
    .sp-total-cardpunks {
      @include fonts(400, rem(14), rem(20) $sp-fontRegular);
      &-title {
        @include fonts(500, rem(22), rem(28) $sp-fontMedium);
      }
    }
    .sp-last-claim {

      @include fonts(400, rem(15), rem(20) $sp-fontRegular);
      padding-bottom: rem(10);
    }
    .sp-punk-character {
      margin-top: rem(15);
      @include fonts(500, rem(18), rem(22) $sp-fontRegular);
    }
    .sp-btn-box {
      margin-top: rem(10);
      .sp-settingBtn {
        width: rem(100);
        height: rem(45);
        @include fonts(500, rem(16), rem(22) $sp-fontMedium);
      }
    }
  }
}
.sp-other-nft{
  .sp-card-header-title{
    margin: rem(20) 0;
  }
}
}
@media only screen and (max-width:1200px){
  .sp-claimpunks {
  .sp-number-box {
    padding: rem(10);
    .sp-starpunks {
      padding: rem(10);
      margin-bottom: rem(15);
      &-title {
        @include fonts(500, rem(16), rem(22), $sp-fontMedium);
      }
    }
    .sp-sumofpunks {
      @include fonts(500, rem(18), rem(24), $sp-fontMedium);
    }
    .sp-btnBox {
      .sp-cliam-btn {
        @include fonts(500, rem(16), rem(22), $sp-fontMedium);
        height: rem(50);
      }
    }
  }
  .sp-starpunk-card {
    padding: rem(10);
    .sp-total-cardpunks {

      &-title {
        @include fonts(500, rem(20), rem(24) $sp-fontMedium);
      }
    }
    .sp-last-claim {

      @include fonts(400, rem(14), rem(20) $sp-fontRegular);
      padding-bottom: rem(10);
    }
    .sp-punk-character {
      margin-top: rem(15);
      @include fonts(500, rem(16), rem(22) $sp-fontRegular);
    }
    .sp-btn-box {
      margin-top: rem(10);
      .sp-settingBtn {
        width: rem(90);
        height: rem(40);
        @include fonts(500, rem(14), rem(20) $sp-fontMedium);
      }
    }
  }
}
.sp-other-nft{
    .sp-card-header-title{
      margin: rem(15) 0;
    }
  }
}