.sp-card {
  $card: &;
  width: 100%;
  border-radius: rem(10);
  background-color: rgba($white, 0.1);
  &.sp-p10 {
    padding: rem(10);
  }
  &.sp-p20 {
    padding: rem(20);
  }
  &.sp-p30 {
    padding: rem(30);
  }
  &.sp-p40 {
    padding: rem(40);
  }
  &.sp-p50 {
    padding: rem(50);
  }
  &-header {
    $h: &;
    color: $white;
    padding: 0 0 rem(30);
    margin: 0 0 rem(30);
    border-bottom: rem(1) solid rgba($white, 0.2);
    @include flex(flex, row wrap, space-between, center, null, null);
    gap: rem(15);
     span {
      width: rem(40);
      height: rem(40);
      @include flex(flex, row wrap, center, center, null, null);
      margin-left: rem(15);
    }
    & &-wrap {
      color: black;
      @include flex(flex, column wrap, null, null, null, null);
      gap: rem(15);
      flex: 1;
    }
    & &-title {
      color: $white;
      @include fonts(400, rem(34), rem(40), $sp-fontRegular);
    }
    & &-desc {
      color: rgba($white, 0.5);
      @include fonts(400, rem(24), rem(32), $sp-fontRegular);
      margin: 0;
    }
  }
}
@media only screen and (max-width:1200px){
  .sp-card{
     &.sp-p30 {
    padding: rem(25);
  }
   &-header{
     $h: &;
    padding: 0 0 rem(25);
    margin: 0 0 rem(25);
    & &-title {
      @include fonts(400, rem(30), rem(36), $sp-fontRegular);
    }
    & &-desc {
      @include fonts(400, rem(22), rem(30), $sp-fontRegular);
    }
    }
  }
}
@media only screen and (max-width:991px){
  .sp-card{
     &.sp-p30 {
    padding: rem(20);
  }
   &-header{
     $h: &;
    padding: 0 0 rem(20);
    margin: 0 0 rem(20);
    & &-title {
      @include fonts(400, rem(26), rem(32), $sp-fontRegular);
    }
    & &-desc {
      @include fonts(400, rem(20), rem(26), $sp-fontRegular);
    }
    }
  }
}
@media only screen and (max-width:767px){
  .sp-card{
     &.sp-p30 {
    padding: rem(15);
  }
   &-header{
     $h: &;
    padding: 0 0 rem(15);
    margin: 0 0 rem(15);
     & &-title {
      @include fonts(400, rem(24), rem(30), $sp-fontRegular);
    }
    & &-desc {
      @include fonts(400, rem(18), rem(24), $sp-fontRegular);
    }
    }
  }
}
@media only screen and (max-width:575px){
  .sp-card{
   &-header{
     $h: &;
     & &-title {
      @include fonts(400, rem(22), rem(28), $sp-fontRegular);
    }
    & &-desc {
      @include fonts(400, rem(16), rem(22), $sp-fontRegular);
    }
    }
  }
}