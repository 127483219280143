.sp-drawerMenu {
  width: 270px;
  background-color: #1F1E23;
  height: 100vh;
  justify-content: space-between;
  flex-flow: column;
  display: flex;
  position: fixed;
  z-index: 2;
  .sp-menuBox {
    width: 100%;
    @include flex(flex, column wrap, null, null, null, null);
    height: 100vh;
    .sp-sidebar-left {
      @include flex(flex, row wrap, space-between, center, null, null);
      width: 270px;
      height: 70px;
      padding: 0 15px;
      @include fonts(500, rem(24), rem(33), $sp-fontMedium);
      // background-color: rgba(colors(sp-white), 0.05);
      &-logo {
        color: colors(sp-primary);
      }
      &-toggel {
        transition: all 0.3s ease;
        display: none;
        &:hover {
          svg path {
            fill: colors(sp-white);
          }
        }
      }
    }
    .sp-sidebar-menu {
      height: auto;
      flex: 1;
      overflow-y: auto;
      &-item {
        @include flex(flex, row wrap, null, null, null, null);
        width: 100%;
        transition: all 0.3s ease;
        a {
          @include flex(flex, row wrap, null, center, null, null);
          height: rem(60);
          padding: 0 rem(15);
          width: 100%;
          opacity: 0.5;
          color: colors(sp-white);
          @include fonts(500, rem(18), rem(28), $sp-fontRegular);
          .sp-menu-icon {
            @include flex(flex, null, null, center, null, null);
            margin-right: rem(10);
          }
          &:hover {
            background-color: rgba(colors(sp-white), 0.04);
            opacity: 1;
          }
          &.active {
            background-color: rgba(colors(sp-white), 0.08);
            opacity: 1;
          }
        }
        .sp-sidebar-menu-item-link-text {
          align-items: center;
          display: flex;
        }
      }
    }
  }
  .sp-userProfile {
    @include flex(flex, row wrap, null, center, null, null);
    padding: rem(15);
    .sp-userLink {
      @include flex(flex, row wrap, null, center, null, null);
      width: 100%;
      .sp-userIcon {
        width: rem(50);
        height: rem(50);
        border-radius: 50%;
        overflow: hidden;
        background-color: colors(sp-black);
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .sp-user {
        margin-left: rem(10);
        @include flex(flex, row wrap, null, center, null, null);
        color: colors(sp-white);
        &-name {
          width: 100%;
          @include fonts(500, rem(18), rem(22) $sp-fontMedium);
        }
        &-balace {
          width: 100%;
          @include fonts(500, rem(14), rem(20) $sp-fontRegular);
          color: rgba(colors(sp-white), 0.5);
        }
      }
    }
    .userkey {
      //@include flex(flex, row wrap, null, center, null, null);
      margin-top: rem(15);
      border: solid 1px rgba(colors(sp-white), 0.1);
      height: 50px;
      padding: 0 rem(15);
      border-radius: 5px;
      color: rgba(colors(sp-white), 0.5);
      width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 50px;
    }
    .usercoin-box {
      @include flex(flex, row wrap, null, null, null, null);
      width: 100%;
      margin: rem(10) 0;
      @include fonts(500, rem(18), rem(26) $sp-fontMedium);
      color: rgba(colors(sp-white), 1);
      .userHavecoin {
        color: rgba(colors(sp-white), 0.5);
        margin-right: rem(10);
      }
    }
    .sp-connect-wallet{
      outline: none;
      @include flex(flex, row wrap, center, center, null, null);
      background-color: rgba(colors(sp-primary), 1);
      border: none;
      height: 50px;
      border-radius: rem(3);
      color: rgba(colors(sp-black), 1);
      width: 100%;
      @include fonts(500, rem(18), rem(26) $sp-fontMedium);
      margin-bottom: rem(10);
    }
    .buymorebtn {
      outline: none;
      @include flex(flex, row wrap, center, center, null, null);
      background-color: rgba(colors(sp-white), 0.3);
      border: none;
      height: 50px;
      border-radius: rem(3);
      color: rgba(colors(sp-white), 1);
      width: 100%;
      @include fonts(500, rem(18), rem(26) $sp-fontMedium);
    }
    .sp-social {
      width: 100%;
      @include flex(flex, row wrap, center, center, null, null);
      margin-top: rem(20);
      &-icon {
        @include flex(flex, row wrap, center, center, null, null);
        &-li {
          @include flex(flex, row wrap, center, center, null, null);
          margin-right: rem(15);
          &:last-child {
            margin-right: 0;
          }
          &-a {
            @include flex(flex, row wrap, center, center, null, null);
            background-color: rgba(colors(sp-white), 0.1);
            width: rem(45);
            height: rem(45);
            border-radius: rem(5);
            svg {
              width: rem(20);
              height: auto;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .sp-drawerMenu {
    width: 270px;
    transform: translateX(-270px);
    transition: $transition;
    .sp-menuBox {
      .sp-sidebar-left {
        &-toggel {
          transition: all 0.3s ease;
          display: block;
        }
      }
    }
  }
  .sp-drawermenu-open {
    .sp-drawerMenu {
      transform: translateX(0px);
    }
  }
}
