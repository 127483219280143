.sp-generateVoucher {
  &-box {
    border-radius: rem(10);
    padding: rem(30);
    background-color: rgba(colors(sp-white), 0.05);
    &-title {
      @include fonts(500, rem(24), rem(34), $sp-fontMedium);
      color: rgba(colors(sp-white), 1);
    }
    &-select {
      margin: rem(15) 0 0;
      height: rem(70);
      @include flex(flex, row wrap, space-between, center, null, null);
      width: 100%;
      border-radius: rem(5);
      border: solid rem(1) rgba(colors(sp-white), 0.2);
      padding: rem(0) rem(10) 0 0;
      background-color: rgba(colors(sp-black), 0.5);
      .sp-toggel {
        @include flex(flex, row wrap, null, center, null, null);
        @include fonts(400, rem(18), rem(26), $sp-fontRegular);
        color: rgba(colors(sp-white), 0.5);
        width: calc(100% - rem(70));
        span {
          margin-left: rem(10);
        }
      }
      .sp-generateInput {
        border: solid rem(1) rgba(colors(sp-white), 0.2);
        text-align: right;
        @include fonts(400, rem(18), rem(26), $sp-fontRegular);
        color: rgba(colors(sp-white), 1);
        background-color: rgba(colors(sp-black), 0.5);
        border-radius: rem(5);
        outline: none;
        width: rem(70);
        height: rem(40);
        padding: 0 rem(10);
        &::placeholder {
          color: rgba(colors(sp-white), 1);
        }
      }
      .sp-totalgenerate {
        border: none;
        background-color: transparent;
        outline: none;
        width: rem(70);
        @include fonts(400, rem(18), rem(26), $sp-fontRegular);
        text-align: right;
        color: rgba(colors(sp-white), 1);
      }
    }
    .sp-generateBtn {
      @include flex(flex, row wrap, center, center, null, null);
      border-radius: rem(5);
      height: rem(70);
      background-color: rgba(colors(sp-primary), 1);
      padding: rem(15);
      @include fonts(500, rem(24), rem(34), $sp-fontMedium);
      color: colors(sp-black);
      width: 100%;
      outline: none;
      border: none;
      margin-top: rem(20);
    }
  }
  .sp-form-group {
    width: auto;
    input[type="radio"] {
      display: none;
    }

    label {
      cursor: pointer;
      position: relative;
      @include fonts(500, rem(24), rem(28), $sp-fontMedium);
      color: colors(sp-white);
      padding-left: rem(45);
    }

    label::before {
      content: "";
      position: absolute;
      width: rem(30);
      height: rem(30);
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 50%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: border-color 400ms ease;
    }

    label::after {
      content: "";
      position: absolute;
      width: rem(26);
      height: rem(26);
      background-color: colors(sp-primary);
      border: 2px solid colors(sp-primary);
      border-radius: 50%;
      top: 50%;
      left: rem(2);
      transform: translateY(-50%) scale(0);
      transition: transform 400ms ease;
    }

    input[type="radio"]:checked + label::before {
      border-color: colors(sp-primary);
    }

    input[type="radio"]:checked + label::after {
      transform: translateY(-50%) scale(0.55);
    }
  }
}
