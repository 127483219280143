.sp-getTshirt {
  width: 100%;
  .sp-card-tshirt {
    @include flex(flex, row wrap, null, null, null, null);
    width: 100%;
    background-color: rgba($white, 0.1);
    border-radius: rem(15);
    padding: rem(20);
    .sp-product-image {
      aspect-ratio: 1 / 1;
      width: 100%;
      border-radius: rem(10);
      overflow: hidden;

      img{
        width: 100%;
        object-fit: cover;
        height: 100%;
        object-position: top;
      }
    }
    .sp-product-detail {
      width: 100%;
      @include flex(flex, row wrap, null, null, null, null);
      margin: rem(10) 0;
      .sp-price {
        color: rgba($white, 0.5);
        @include fonts(400, rem(18), rem(22) $sp-fontRegular);
        width: 100%;
      }
      .sp-product-price {
        color: rgba($white, 1);
        @include fonts(500, rem(24), rem(32) $sp-fontMedium);
        width: 100%;
      }
      .sp-sizeBox {
        @include flex(flex, row wrap, null, null, null, null);
        width: 100%;
        margin-top: rem(10);
        &-ul {
          width: 100%;
          margin: 0;
          padding: 0;
          @include flex(flex, row wrap, null, null, null, null);
          gap: rem(10);
          .sp-unselect {
            width: rem(30);
            height: rem(30);
            @include flex(flex, row wrap, center, center, null, null);
            background-color: rgba($white, 0.1);
            border-radius: rem(15);
            @include fonts(500, rem(16), rem(20) $sp-fontMedium);
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              background-color: colors(sp-hover);
              color: colors(sp-black);
            }
            &.sp-selected {
              background-color: colors(sp-hover);
              color: colors(sp-black);
            }
          }
        }
      }
    }
    .sp-addtocart {
      @include flex(flex, row wrap, center, center, null, null);
      width: 100%;
      height: rem(51);
      border-radius: rem(5);
      background-color: colors(sp-primary);
      transition: all 0.3s ease;
      margin: rem(5) 0 0;
      @include fonts(600 rem(18), rem(24) $sp-fontMedium);
      border: none;
      outline: none;
      svg{
        margin-right: rem(15);
      }
      &:hover{
        background-color: colors(sp-hover);
      }
    }
  }
}
