.sp-tableRow {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  overflow-y: auto;
}
.sp-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
  &-thead {
    .sp-table-th {
      text-align: left;
      border: none;
      padding: rem(15);
      @include fonts(400, rem(20), rem(26), $sp-fontRegular);
      color: rgba(colors(sp-white), 0.5);
      background-color: rgba(colors(sp-white), 0.05);
    }
  }
  &-tbody {
    .sp-table-tr {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px dashed colors(sp-border);
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
    .sp-table-td {
      text-align: left;
      border: none;
      padding: rem(10) rem(15);
      font: 16px/24px fonts(regular);
      .sp-tableImage {
        width: 45px;
        height: 45px;
        border-radius: 5px;
        overflow: hidden;
        .sp-img {
          width: 100%;
        }
      }
      .sp-tabelText {
        margin-left: 10px;
        font-family: fonts(medium);
      }
    }
  }
}
.sp-tablePagination {
  width: 100%;
  margin-top: rem(15);
  .sp-pagination {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    .sp-paginate_button {
      display: flex;
      width: 30px;
      height: 30px;
      border-radius: #{$space * 1 - 10};
      margin: 0 5px 0 0;
      background-color: rgba(colors(sp-primary), 0.5);
      color: colors(sp-black);
      &.active {
        background-color: rgba(colors(sp-primary), 1);
        .page-link {
          color: colors(sp-white);
        }
      }
      .page-link {
        outline: none;
        border: none;
        width: 100%;
        background: transparent;
        color: colors(sp-black);
      }
    }
  }
  .sp-dataTables_length {
    width: 100%;
    justify-content: flex-end;
    label {
      width: 60px;
      background-color: rgba(colors(sp-white), 0.05);
      justify-content: center;
      border-radius: 5px;
      margin-right: 15px;
      height: #{$space * 2};
      align-items: center;
      display: flex;
      justify-content: center;
      .sp-custom-select {
        border: none;
        background-color: transparent;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        font-size: 0.875rem;
        color: colors(sp-white);
        option {
          color: rgba(colors(sp-black), 1);
        }
      }
    }
    .sp-dataTables_info {
      @include fonts(400, rem(14), rem(16), $sp-fontRegular);
    }
  }
}
.sp-blankScroll {
  width: 100%;
  overflow-x: auto;
}
@media only screen and (max-width: 1536px) {
  .sp-table {
    &-thead {
      .sp-table-th {
        @include fonts(400, rem(14), rem(22), $sp-fontMedium);
      }
    }
    &-tbody {
      .sp-table-td {
        padding: 10px 0;
        @include fonts(400, rem(14), rem(22), $sp-fontRegular);
        .sp-tableImage {
          width: 40px;
          height: 40px;
          border-radius: 4px;
        }
        .sp-tabelText {
          margin-left: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .sp-table {
    .sp-tableRow {
      display: flex;
      flex-flow: row wrap;
      min-width: 750px;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
@media only screen and (max-width: 1310px) {
  .sp-table {
    .sp-tableRow {
      display: flex;
      flex-flow: row wrap;
      min-width: 510px;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

@media only screen and (max-width: 767px) {
  .sp-tablePagination {
    .sp-dataTables_length {
      justify-content: flex-start;
    }
  }
}
