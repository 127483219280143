@use "sass:math";

@function colors($color-name) {
  @return map-get($colors, $color-name);
}

@function getIcon($src, $iconName) {
  @return map-get(map-get($image-storage, $src), $iconName);
}
//flex
@mixin flex($display: null, $flow: null, $justify-c: null, $align-i: null, $align-c: null, $align-s: null) {
  display: $display;
  flex-flow: $flow;
  justify-content: $justify-c;
  align-items: $align-i;
  align-content: $align-c;
  align-self: $align-s;
}
//btn
@mixin btn(
  $display: null,
  $direction: null,
  $wrap: null,
  $flow: null,
  $justify-c: null,
  $align-i: null,
  $align-c: null,
  $align-s: null,
  $font: null,
  $color: null,
  $border: null,
  $border-radius: null,
  $padding: null,
  $bg-color: null,
  $text-transform: null,
  $width: null
) {
  display: $display;
  flex-flow: $flow;
  flex-wrap: $wrap;
  justify-content: $justify-c;
  align-items: $align-i;
  align-content: $align-c;
  align-self: $align-s;
  font: $font;
  color: $color;
  border: $border;
  border-radius: $border-radius;
  padding: $padding;
  background-color: $bg-color;
  transition: $transition-base;
  text-transform: $text-transform;
  width: $width;
}
//fonts
@mixin fonts($font-w: null, $font-s: null, $font-l: null, $font-f: null) {
  font: $font-w #{$font-s}/#{$font-l} $font-f;
}
//bg image
@function getImage($src, $imageName) {
  @return map-get(map-get($image-storage, $src), $imageName);
}
//box shadow
@mixin shadow($x: null, $y: null, $blur: null, $color: null) {
  box-shadow: $x $y $blur $color;
}
//position
@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
//px to rem
$html-font-size: 16px;
@function stripUnit($value) {
  // @return $value / ($value * 0 + 1);
  @return math.div($value, $value * 0 + 1);
}
@function rem($pxValue) {
  // @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
  @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}
//background
@mixin bg(
  $image: null,
  $position: null,
  $size: null,
  $repeat: null,
  $attachment: null,
  $origin: null,
  $clip: null,
  $color: null
) {
  background: $image $position #{"/"} $size $repeat $attachment $origin $clip $color;
}
//placeholder
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}
