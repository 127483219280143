.sp-punkbank {
  width: 100%;
  @include flex(flex, row wrap, null null, null, null);
  .sp-punks {
    display: grid;
    gap: rem(30);
    grid-template-columns: repeat(4, 1fr);
    &-image{
      @include flex(flex, row wrap,null,null,null,null);
      aspect-ratio: 1 / 1;
      width: 100%;
      overflow: hidden;
      border-radius: rem(10);
    }
  }
}
@media only screen and (max-width:1536px){
.sp-punkbank {
  .sp-punks {
    gap: rem(25);
    grid-template-columns: repeat(4, 1fr);
  }
}
}
@media only screen and (max-width:1200px){
  .sp-punkbank {
  .sp-punks {
    gap: rem(20);
    grid-template-columns: repeat(4, 1fr);
  }
}
}

@media only screen and (max-width:991px){
  .sp-punkbank {
  .sp-punks {
    gap: rem(20);
    grid-template-columns: repeat(3, 1fr);
  }
}
}
@media only screen and (max-width:767px){
   .sp-punkbank {
  .sp-punks {
    gap: rem(15);
    grid-template-columns: repeat(2, 1fr);
  }
}
}
