.sp-generateVoucher-box.radio {
  border-radius: rem(10);
  padding: rem(30);
  background-color: rgba(colors(sp-white), 0.05);
  &-title {
    @include fonts(500, rem(24), rem(34), $sp-fontMedium);
    color: rgba(colors(sp-white), 1);
  }
  .sp-generateVoucher-box-select {
    margin: rem(15) 0 0;
    height: rem(70);
    @include flex(flex, row wrap, space-between, center, null, null);
    width: 100%;
    border-radius: rem(5);
    border: solid rem(1) rgba(colors(sp-white), 0.2);
    padding: 0 rem(10);
    background-color: rgba(colors(sp-black), 0.5);
    .sp-toggel {
      @include flex(flex, row wrap, null, center, null, null);
      @include fonts(400, rem(18), rem(26), $sp-fontRegular);
      color: rgba(colors(sp-white), 0.5);
      width: calc(100% - rem(70));
      span {
        margin-left: rem(10);
      }
    }
    label {
      @include fonts(400, rem(18), rem(26), $sp-fontRegular);
      color: rgba(colors(sp-white), 0.5);
    }
    .sp-generateInput {
      border: solid rem(1) rgba(colors(sp-white), 0.2);
      text-align: right;
      @include fonts(400, rem(18), rem(26), $sp-fontRegular);
      color: rgba(colors(sp-white), 1);
      background-color: rgba(colors(sp-black), 0.5);
      border-radius: rem(5);
      outline: none;
      width: rem(70);
      height: rem(40);
      padding: 0 rem(10);
      &::placeholder {
        color: rgba(colors(sp-white), 1);
      }
    }
    .sp-totalgenerate {
      border: none;
      background-color: transparent;
      outline: none;
      width: rem(70);
      @include fonts(400, rem(18), rem(26), $sp-fontRegular);
      text-align: right;
      color: rgba(colors(sp-white), 1);
    }
  }
  // .sp-generateBtn {
  //   @include flex(flex, row wrap, center, center, null, null);
  //   border-radius: rem(5);
  //   height: rem(70);
  //   background-color: rgba(colors(sp-primary), 1);
  //   padding: rem(15);
  //   @include fonts(500, rem(24), rem(34), $sp-fontMedium);
  //   color: colors(sp-black);
  //   width: 100%;
  //   outline: none;
  //   border: none;
  //   margin-top: rem(20);
  // }
}
