.sp-mintNow {
  .sp-form-control {
    height: rem(70);
    padding: rem(15) rem(20);
  }
  .sp-radio-buttons {
    height: rem(70);
    padding: rem(10) rem(20);
    border-radius: rem(5);
    background-color: rgba(colors(sp-black), 0.5);
    border: rem(1) solid rgba(colors(sp-border), 0.5);
    width: 100%;
  }
  .sp-form-group-wrap {
    @include flex(flex, row wrap, null, null, null, null);
    gap: rem(20);
    .sp-form-control {
      flex: 1;
    }
    .sp-voucher-apply {
      @include flex(flex, row, null, center, null, null);
      gap: rem(20);
      width: 100%;
      &-code {
        @include flex(flex, row, space-between, center, null, null);
        width: 100%;
        span {
          opacity: 0.5;
        }
      }
      &-price {
        @include flex(flex, row, space-between, center, null, null);
        width: 100%;
        span {
          opacity: 0.5;
        }
      }
      &-cancel {
        @include flex(flex, row wrap, center, center, null, null);
        background-color: colors(sp-primary);
        border-radius: rem(5);
        outline: none;
        border: none;
        @include fonts(500, rem(22), rem(30), $sp-fontMedium);
        color: rgba(colors(sp-black), 1);
        transition: all 0.3s ease;
        padding: 0 rem(30);
        height: 100%;
        &:hover {
          background-color: colors(sp-hover);
        }
      }
    }
  }
  .sp-btn {
    padding: rem(20) rem(50);
    background-color: colors(sp-primary);
    border-radius: rem(5);
    border: none;
    color: colors(sp-black);
    @include fonts(600, rem(24), rem(28), $sp-fontBold);
  }
  &-item {
    .sp-card {
      &-header {
        padding: 0 0 rem(20);
        margin: 0 0 rem(20);
        &-title {
          color: colors(sp-primary);
          @include fonts(600, rem(64), rem(64), $sp-fontBold);
        }
      }
    }
    &-subtitle {
      color: colors(sp-whitr);
      @include fonts(500, rem(24), rem(32), $sp-fontMedium);
      margin: 0 0 rem(8);
    }
    &-desc {
      @include flex(flex, row wrap, null, center, null, null);
      @include fonts(400, rem(18), rem(22), $sp-fontRegular);
      gap: rem(8);
      opacity: 0.5;
      margin: 0;
    }
  }
}
.sp-mintNow-text {
  @include fonts(400, rem(30), rem(40), $sp-fontRegular);
}
@media only screen and (max-width: 1536px) {
  .sp-mintNow {
    .sp-form-control {
      height: rem(65);
      padding: rem(15) rem(15);
    }
    .sp-radio-buttons {
      height: rem(65);
      padding: rem(10) rem(15);
    }
    .sp-form-group-wrap {
      gap: rem(15);
    }
    .sp-btn {
      padding: rem(15) rem(30);
      @include fonts(600, rem(22), rem(26), $sp-fontBold);
    }
    &-item {
      .sp-card {
        &-header {
          padding: 0 0 rem(15);
          margin: 0 0 rem(15);
          &-title {
            @include fonts(600, rem(58), rem(64), $sp-fontBold);
          }
        }
      }
      &-subtitle {
        @include fonts(500, rem(22), rem(30), $sp-fontMedium);
      }
      &-desc {
        @include fonts(400, rem(16), rem(20), $sp-fontRegular);
      }
    }
  }
  .sp-mintNow-text {
    @include fonts(400, rem(28), rem(36), $sp-fontRegular);
  }
}
@media only screen and (max-width: 1200px) {
  .sp-mintNow-text {
    @include fonts(400, rem(26), rem(32), $sp-fontRegular);
  }
}
@media only screen and (max-width: 991px) {
  .sp-mintNow-text {
    @include fonts(400, rem(24), rem(30), $sp-fontRegular);
  }
}
@media only screen and (max-width: 767px) {
  .sp-mintNow-text {
    @include fonts(400, rem(22), rem(30), $sp-fontRegular);
  }
}
