.sp-buyPunks {
  @include flex(flex, column wrap, null, space-between, null, null);
  gap: rem(20);

  &-text {
    p {
      @include fonts(400, rem(36), rem(44), $sp-fontRegular);
      color: colors(sp-primary);

      &:not(:last-child) {
        margin-bottom: rem(30);
      }
    }
  }

  &-price {
    @include flex(flex, row wrap, space-between, center, null, null);
    gap: rem(20);
    border-radius: rem(5);
    background-color: colors(sp-black);
    border: rem(1) solid rgba(colors(sp-border), 0.5);
    padding: rem(20);
    width: 100%;

    &-label {
      @include fonts(400, rem(34), rem(40), $sp-fontRegular);
      color: colors(sp-primary);
      margin-bottom: 0;
    }

    &-value {
      @include fonts(600, rem(42), rem(50), $sp-fontBold);
      color: colors(sp-white);
    }
  }

  .sp-voucher-apply-cancel {
    cursor: pointer;
  }
}

@media only screen and (max-width: 1536px) {
  .sp-buyPunks {
    &-text {
      p {
        @include fonts(400, rem(34), rem(42), $sp-fontRegular);

        &:not(:last-child) {
          margin-bottom: rem(25);
        }
      }
    }

    &-price {
      &-label {
        @include fonts(400, rem(32), rem(38), $sp-fontRegular);
      }

      &-value {
        @include fonts(600, rem(38), rem(46), $sp-fontBold);
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .sp-buyPunks {
    &-text {
      p {
        @include fonts(400, rem(24), rem(30), $sp-fontRegular);

        &:not(:last-child) {
          margin-bottom: rem(20);
        }
      }
    }

    &-price {
      &-label {
        @include fonts(400, rem(24), rem(30), $sp-fontRegular);
      }

      &-value {
        @include fonts(600, rem(28), rem(34), $sp-fontBold);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .sp-buyPunks {
    &-text {
      p {
        @include fonts(400, rem(22), rem(28), $sp-fontRegular);

        &:not(:last-child) {
          margin-bottom: rem(15);
        }
      }
    }

    &-price {
      &-label {
        @include fonts(400, rem(20), rem(26), $sp-fontRegular);
      }

      &-value {
        @include fonts(600, rem(24), rem(30), $sp-fontBold);
      }
    }
  }
}

.tick {
  font-size: 3em;
}