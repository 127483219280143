.sp-comicbook {
  width: 100%;
  &-content {
    width: 100%;
    border-radius: rem(10);
    background-color: colors(sp-blue);
    padding: 0 0 rem(30);
    &-bg {
      @include flex(flex, row wrap, null, null, null, null);
      width: 100%;
      position: relative;
      border-radius: rem(15) rem(15) 0 0;
      overflow: hidden;
      .comicImg {
        width: 100%;
      }
      .sp-image-text {
        position: absolute;
        width: 100%;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(55, 47, 64, 1) 87%,
          rgba(25, 16, 35, 1) 100%
        );
        height: 100%;
        padding: rem(30);
        @include flex(flex, row wrap, center, flex-end, null, null);
        .sp-story {
          width: 100%;
          @include flex(flex, row wrap, center, center, null, null);
          &-title {
            color: colors(sp-white);
            @include fonts(600, rem(34), rem(44), $sp-fontBold);
            &-story {
              width: 90%;
              color: rgba(colors(sp-white), 0.7);
              @include fonts(400, rem(22), rem(34), $sp-fontRegular);
              margin: rem(20) 0 0;
              text-align: center;
            }
          }
        }
      }
    }
    &-storyImg {
      @include flex(flex, row wrap, center, center, null, null);
      width: 100%;
      object-fit: cover;
      padding: 0 rem(40);
    }
    .sp-storyImg-Content {
      @include flex(flex, row wrap, null, null, null, null);
      width: 100%;
      padding: 0 rem(40) 0;
      span {
        width: 100%;
        text-transform: uppercase;
        @include fonts(500, rem(22), rem(30), $sp-fontRegular);
        color: rgba(colors(sp-white), 1);
      }
      &-title {
        @include fonts(700, rem(42), rem(52), $sp-fontBold);
        margin-bottom: rem(15);
        background: linear-gradient(to right, #da3b59 0%, #f8d254 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
      &-discription {
        @include fonts(500, rem(22), rem(30), $sp-fontRegular);
        color: rgba(colors(sp-white), 0.5);
        width: 100%;
      }
    }
    .sp-legendry {
      @include flex(flex, row wrap, null, null, null, null);
      width: 100%;
      &-img {
        @include flex(flex, row wrap, null, null, null, null);
        width: 100%;
        aspect-ratio: 16 / 9;
        margin-bottom: rem(30);
      }
    }
  }
  .sp-comic-video {
    width: 100%;
    position: relative;
    margin-bottom: rem(30);
    &-content {
      @include flex(flex, row wrap, center, center, null, null);
      width: 100%;
      border-radius: rem(15);
      overflow: hidden;
    }
    .sp-previewtext {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      @include flex(flex, row wrap, center, center, null, null);
      padding: rem(15);
      .sp-playBtn {
        @include flex(flex, row wrap, null, center, null, null);
        border: solid rem(1) rgba(colors(sp-white), 1);
        border-radius: rem(30);
        width: rem(132);
        height: rem(40);
        background: transparent;
        margin-top: rem(15);
        color: rgba(colors(sp-white), 1);
        @include fonts(400, rem(16), rem(22), $sp-fontRegular);
        .sp-playicon {
          width: rem(25);
          height: rem(25);
          margin-right: rem(10);
        }
      }
    }
    .sp-playBtnRound {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      outline: none;
      border: none;
      background-color: rgba(colors(sp-primary), 0.7);
      width: rem(50);
      height: rem(50);
      border-radius: rem(30);
      @include flex(flex, row wrap, center, center, null, null);
      transition: all 0.3s ease;
      &:hover {
        background-color: rgba(colors(sp-primary), 1);
      }
      .triangle {
        width: rem(24);
        margin-bottom: rem(1);
      }
    }
  }
}

@media only screen and (max-width: 1536px) {
  .sp-comicbook {
    &-content {
      padding: 0 0 rem(30);
      &-bg {
        .sp-image-text {
          .sp-story {
            &-title {
              @include fonts(600, rem(32), rem(40), $sp-fontBold);
              &-story {
                width: 100%;
                @include fonts(400, rem(20), rem(30), $sp-fontRegular);
                margin: rem(15) 0 0;
              }
            }
          }
        }
      }
      &-storyImg {
        padding: 0 rem(30);
      }
      .sp-storyImg-Content {
        padding: 0 rem(30) 0;
        span {
          @include fonts(500, rem(20), rem(30), $sp-fontRegular);
        }
        &-title {
          @include fonts(700, rem(38), rem(48), $sp-fontBold);
        }
        &-discription {
          @include fonts(500, rem(20), rem(30), $sp-fontRegular);
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .sp-comicbook {
    &-content {
      padding: 0 0 rem(25);
      &-bg {
        .sp-image-text {
          .sp-story {
            &-title {
              @include fonts(600, rem(28), rem(34), $sp-fontBold);
              &-story {
                @include fonts(400, rem(18), rem(26), $sp-fontRegular);
                margin: rem(15) 0 0;
              }
            }
          }
        }
      }
      &-storyImg {
        padding: 0 rem(25);
      }
      .sp-storyImg-Content {
        padding: 0;
        span {
          @include fonts(500, rem(18), rem(26), $sp-fontRegular);
        }
        &-title {
          @include fonts(700, rem(34), rem(42), $sp-fontBold);
        }
        &-discription {
          @include fonts(500, rem(18), rem(26), $sp-fontRegular);
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .sp-comicbook {
    &-content {
      padding: 0 0 rem(25);
      .row.rowreverse {
        flex-direction: column-reverse;
      }
      .sp-comicbook-content-storyImg {
        width: 50%;
        margin: 0 auto;
      }
      &-bg {
        .sp-image-text {
          .sp-story {
            &-title {
              @include fonts(600, rem(24), rem(30), $sp-fontBold);
              &-story {
                @include fonts(400, rem(16), rem(24), $sp-fontRegular);
                margin: rem(15) 0 0;
              }
            }
          }
        }
      }
      &-storyImg {
        padding: 0 rem(20);
      }
      .sp-storyImg-Content {
        padding: 0;
        span {
          @include fonts(500, rem(16), rem(24), $sp-fontRegular);
        }
        &-title {
          @include fonts(700, rem(30), rem(36), $sp-fontBold);
        }
        &-discription {
          @include fonts(500, rem(16), rem(24), $sp-fontRegular);
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .sp-comicbook {
    &-content {
      padding: 0 0 rem(20);
      .sp-comicbook-content-storyImg {
        width: 55%;
        margin: 0 auto;
      }
      &-bg {
        border-radius: rem(5);
        .sp-image-text {
          position: relative;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(55, 47, 64, 1) 87%,
            rgba(25, 16, 35, 1) 100%
          );
          padding: rem(15) 0;
          .sp-story {
            @include flex(flex, row wrap, start, start, null, null);
            &-title {
              @include fonts(600, rem(22), rem(28), $sp-fontBold);
              @include flex(flex, row wrap, start, start, null, null);
              &-story {
                margin: rem(10) 0 0;
                text-align: left;
                @include flex(flex, row wrap, start, start, null, null);
              }
            }
          }
        }
      }
      &-storyImg {
        padding: 0 rem(15);
      }
      .sp-storyImg-Content {
        padding: 0;
        span {
          @include fonts(500, rem(14), rem(20), $sp-fontRegular);
        }
        &-title {
          @include fonts(700, rem(26), rem(32), $sp-fontBold);
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
}
