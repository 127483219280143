.sp-share-connect-wallet {
  margin-bottom: rem(30);
  @include flex(flex, column, center, center, null, null);
  &-text {
    color: colors(sp-white);
    @include fonts(500, rem(42), rem(52), $sp-fontMedium);
  }
  .sp-connectBtn {
    color: colors(sp-black);
    padding: rem(18) rem(25);
    border-radius: rem(5);
    background-color: colors(sp-primary);
    transition: all 0.3s ease;
    @include fonts(500, rem(24), rem(34), $sp-fontMedium);
    @include flex(flex, row wrap, center, center, null, null);
    margin-top: rem(30);
    outline: none;
    border: none;
    &:hover {
      background-color: color(sp-hover);
    }
  }
}
@media only screen and (max-width: 1536px) {
  .sp-share-connect-wallet {
    &-text {
      @include fonts(500, rem(38), rem(50), $sp-fontMedium);
    }
    .sp-connectBtn {
      padding: rem(17) rem(24);
      @include fonts(500, rem(22), rem(30), $sp-fontMedium);
    }
  }
}
@media only screen and (max-width: 1200px) {
  .sp-share-connect-wallet {
    &-text {
      @include fonts(500, rem(34), rem(44), $sp-fontMedium);
    }
    .sp-connectBtn {
      padding: rem(16) rem(23);
      @include fonts(500, rem(20), rem(26), $sp-fontMedium);
    }
  }
}
@media only screen and (max-width: 991px) {
  .sp-share-connect-wallet {
    &-text {
      @include fonts(500, rem(30), rem(40), $sp-fontMedium);
    }
    .sp-connectBtn {
      padding: rem(15) rem(22);
      @include fonts(500, rem(18), rem(24), $sp-fontMedium);
    }
  }
}
@media only screen and (max-width: 767px) {
  .sp-share-connect-wallet {
    &-text {
      @include fonts(500, rem(24), rem(34), $sp-fontMedium);
    }
    .sp-connectBtn {
      padding: rem(15) rem(22);
      @include fonts(500, rem(16), rem(22), $sp-fontMedium);
      margin-top: rem(15);
    }
  }
}
