.sp-header {
  width: 100%;
  height: 50px;
  @include flex(flex, row wrap, space-between, center, null, null);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: colors(sp-black);
  .sp-logo {
    @include fonts(500, rem(24), rem(29), $sp-fontMedium);
    color: colors(sp-primary);
    padding-left: 15px;
    opacity: 1;
  }
  .sp-iconbar {
    @include flex(flex, null, null, null, null, null);
    padding-right: 15px;
    .sp-icons {
      @include flex(flex, null, null, center, null, null);
      @include position(relative, null, null, null, null);
      width: 25px;
      height: 25px;
      transition: all 0.3s ease;
      &:not(:last-child) {
        margin-right: rem(20);
      }
      .sp-notification-number {
        @include flex(flex, null, center, center, null, null);
        border-radius: 50%;
        width: rem(16);
        height: rem(16);
        @include position($position: absolute, $top: rem(-3), $right: rem(-3), $bottom: null, $left: null);
        background-color: colors(sp-red);
        @include fonts(400, rem(12), rem(14), $sp-fontRegular);
        color: colors(sp-white);
      }
      svg {
        opacity: 0.5;
        transition: $transition-base;
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
    // .sp-searchBox {
    //   @include flex(flex, null, null, center, null, null);
    //   width: 25px;
    //   height: 25px;
    //   margin-right: rem(25);
    //   transition: all 0.3s ease;
    //   &:hover {
    //     svg #Group_1717 {
    //       opacity: 1;
    //     }
    //   }
    // }
    // .sp-notification {
    //   @include flex(flex, row wrap, center, center, null, null);
    //   width: 25px;
    //   height: 25px;
    //   position: relative;
    //   transition: all 0.3s ease;
    //   &:hover {
    //     svg #notifiaction-icon {
    //       opacity: 1;
    //     }
    //   }
    //   .sp-notification-number {
    //     @include flex(flex, null, center, center, null, null);
    //     border-radius: 50%;
    //     width: rem(16);
    //     height: rem(16);
    //     @include position($position: absolute, $top: rem(-3), $right: rem(-3), $bottom: null, $left: null);
    //     background-color: colors(sp-red);
    //     @include fonts(400, rem(12), rem(14), $sp-fontRegular);
    //     color: colors(sp-white);
    //   }
    // }
    .sp-menuicon {
      @include flex(none, row wrap, center, center, null, null);
    }
  }
}
@media only screen and(max-width:991px) {
  .sp-header {
    .sp-iconbar {
      .sp-menuicon {
        @include flex(flex, row wrap, center, center, null, null);
      }
    }
  }
}
