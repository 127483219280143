.sp-upcoming-image {
  width: 100%;
  border-radius: rem(10);
  @include position(relative, null, null, null, null);
}
.sp-buynow {
  @include position(absolute, rem(10), rem(10), null, null);
  height: rem(40);
  color: rgba(colors(sp-black), 1);
  @include fonts(400, rem(16), rem(28), $sp-fontMedium);
  border-radius: 5px;
  @include flex(flex, null, center, center, null, null);
  background-color: rgba(colors(sp-primary), 1);
  padding: 0 rem(20);
  &:hover {
    background-color: colors(sp-hover);
    color: rgba(colors(sp-black), 1);
  }
}
