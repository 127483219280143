@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// Include any optional Bootstrap CSS as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/buttons";

// Optionally include utilities API last to generate classes based on the Sass map in `_utililies.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

$enable-grid-classes: true;
$enable-cssgrid: true;

$form-check-input-width: 25px;
$form-check-min-height: 25px;
$form-check-padding-start: 35px;
