.sp-claimpunks {
  .sp-connect-wallet {
    position: relative;
    width: 100%;
    @include flex(flex, row wrap, space-between, null, null, null);
    &-text {
      width: calc(100% - 200px);
      @include fonts(400, rem(24), rem(30), $sp-fontRegular);
      color: rgba(colors(sp-white), 0.5);
    }
    .sp-connectBtn {
      border-radius: rem(5);
      transition: all 0.3s ease;
      height: rem(70);
      @include flex(flex, row wrap, center, center, null, null);
      background-color: colors(sp-primary);
      padding: 0 rem(30);
      outline: none;
      @include fonts(500, rem(20), rem(28), $sp-fontMedium);
      border: none;
      &:hover {
        background-color: colors(sp-hover);
      }
    }
  }
}
