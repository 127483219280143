.sp-games {
  @include flex(flex, row wrap, null, null, null, null);
  width: 100%;
  .sp-coming-soon {
    @include flex(flex, row, space-between, center, null, null);
    width: 100%;
    padding: rem(30);
    background: rgb(99, 113, 205);
    background: radial-gradient(circle, rgba(99, 113, 205, 1) 0%, rgba(130, 140, 217, 1) 100%);
    border-radius: rem(20);
    @include fonts(400, rem(62), rem(48), $sp-fontRegular);
    color: rgba($white, 0.2);
    position: relative;
    .sp-games-icon {
      width: 40%;
      img {
        width: 100%;
      }
    }
    &-textBox {
      width: calc(100% - 40%);
      color: colors(sp-white);
      &-title {
        @include fonts(500, rem(46), rem(56), $sp-fontMedium);
      }
      &-subtitle {
        @include fonts(500, rem(22), rem(32), $sp-fontRegular);
        color: rgba(colors(sp-white), 0.5);
        margin: rem(15) 0 rem(30);
      }
    }
    &-review {
      @include flex(flex, row wrap, null, center, null, null);
      &-gameicon {
        border-radius: rem(15);
        border: solid 2px rgba(colors(sp-white), 1);
        margin-right: rem(15);
      }
      &-text {
        &-title {
          @include fonts(500, rem(22), rem(32), $sp-fontRegular);
        }
        &-subtitle {
          @include fonts(500, rem(18), rem(22), $sp-fontMedium);
          color: rgba(colors(sp-white), 0.5);
        }
      }
    }
  }
  .sp-champ {
    margin-top: rem(30);
    .sp-card-header {
      border: none;
      margin: 0;
    }
    &-box {
      border-radius: rem(10);
      padding: rem(15);
      background-color: rgba($white, 0.1);
      &-image {
        aspect-ratio: 1 / 1;
        overflow: hidden;
        border-radius: rem(5);
        margin-bottom: rem(15);
      }
      &-text {
        color: rgba(colors(sp-white), 1);
        &-title {
          @include fonts(500, rem(22), rem(32), $sp-fontRegular);
        }
        &-subtitle {
          margin: rem(10) 0;
          color: rgba(colors(sp-white), 0.5);
          @include fonts(100, rem(18), rem(26), $sp-fontRegular);
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: rem(50);
        }
        &-footer {
          @include flex(flex, row wrap, space-between, center, null, null);
          margin-top: rem(15);
          .sp-champ-card-viewBtn {
            @include flex(flex, row wrap, center, center, null, null);
            width: rem(45);
            height: rem(45);
            transition: all 0.3s ease;
            background-color: rgba(colors(sp-primary), 1);
            border-radius: 50%;
            outline: none;
            border: none;
            &:hover {
              background-color: colors(sp-hover);
            }
          }
          .sp-champ-box-gameicon {
            @include flex(flex, row wrap, null, center, null, null);
            .sp-gameLogo {
              width: rem(50);
              height: rem(50);
              border-radius: rem(5);
              border: solid rem(2) colors(sp-white);
              margin-right: rem(10);
            }
            .sp-gameLogo-text {
              &-title {
                @include fonts(400, rem(20), rem(24), $sp-fontRegular);
              }
              &-subtitle {
                @include fonts(400, rem(16), rem(22), $sp-fontMedium);
                color: rgba(colors(sp-white), 0.5);
              }
            }
          }
        }
      }
    }
  }
}
// @media only screen and (max-width: 1536px) {
//   .sp-games {
//     .sp-coming-soon {
//       height: calc(100vh - 359px);
//       @include fonts(400, rem(52), rem(42), $sp-fontRegular);
//       .sp-games-icon {
//         width: rem(300);
//         margin-bottom: rem(30);
//         svg {
//           width: 100%;
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 1200px) {
//   .sp-games {
//     .sp-coming-soon {
//       height: calc(100vh - 320px);
//       @include fonts(400, rem(42), rem(50), $sp-fontRegular);
//       .sp-games-icon {
//         width: rem(250);
//         margin-bottom: rem(0);
//       }
//     }
//   }
// }
// @media only screen and (max-width: 991px) {
//   .sp-games {
//     .sp-coming-soon {
//       height: calc(100vh - 300px);
//       @include fonts(400, rem(36), rem(40), $sp-fontRegular);
//       .sp-games-icon {
//         width: rem(230);
//         margin-bottom: rem(0);
//       }
//     }
//   }
// }
// @media only screen and (max-width: 767px) {
// }
// @media only screen and (max-width: 575px) {
// }
