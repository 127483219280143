@font-face {
  font-family: $sp-fontUltralight;
  src: url("../assets/fonts/SFProDisplay-Ultralight.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Ultralight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $sp-fontThin;
  src: url("../assets/fonts/SFProDisplay-Thin.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $sp-fontLight;
  src: url("../assets/fonts/SFProDisplay-Light.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $sp-fontRegular;
  src: url("../assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $sp-fontMedium;
  src: url("../assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $sp-fontBold;
  src: url("../assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $sp-fontHeavy;
  src: url("../assets/fonts/SFProDisplay-Heavy.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $sp-fontBlack;
  src: url("../assets/fonts/SFProDisplay-Black.woff2") format("woff2"),
    url("../assets/fonts/SFProDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
body {
  width: 100%;
  // @include fonts(400, rem(18), rem(26), $jf-fontRegular);
  margin: 0;
  padding: 0;
  color: colors(sp-white);
  background-color: colors(sp-black);
  position: relative;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
  a {
    text-decoration: none;
    color: colors(jf-primary);
    transition: all 0.3s ease;
    &:focus {
      color: colors(jf-primary);
    }
    &:hover {
      color: colors(jf-secondary);
    }
  }
}
* {
  box-sizing: border-box;
  outline: none;
  &:focus {
    outline: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a {
  text-decoration: none;
  color: colors(jf-primary);
  transition: all 0.3s ease;
  &:focus {
    color: colors(jf-primary);
  }
  &:hover {
    color: colors(jf-secondary);
  }
}
.jf-mt-20 {
  margin-top: rem(20) !important;
}
img {
  width: 100%;
}
//selections

//scrollbar
.row {
  row-gap: rem(25);
}
::-webkit-scrollbar {
  width: rem(5);
  height: rem(5);
}
::-webkit-scrollbar-track {
  background: rgba(colors(sp-primary), 0);
}
::-webkit-scrollbar-thumb {
  background-color: colors(sp-primary);
  border-radius: rem(5);
}
@include media-breakpoint-down(md) {
  .row {
    row-gap: rem(15);
  }
}
