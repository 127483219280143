.sp-share {
  width: 100%;
  .sp-voucherBox {
    &-title {
      @include fonts(400, rem(24), rem(34), $sp-fontRegular);
    }
    &-subtitle {
      @include fonts(400, rem(20), rem(26), $sp-fontRegular);
      margin: rem(15) 0 rem(10);
    }
    .sp-voucherUsed {
      @include fonts(400, rem(20), rem(26), $sp-fontRegular);
      margin-top: rem(20);
      @include flex(flex, row wrap, null, center, null, null);
    }
    &-list {
      @include flex(flex, row wrap, space-between, center, null, null);
      width: 100%;
      gap: rem(15);
      margin-bottom: rem(15);
      .sp-invitefrd {
        @include flex(flex, row wrap, center, center, null, null);
        border-radius: rem(5);
        outline: none;
        border: none;
        background-color: rgba(colors(sp-primary), 1);
        padding: rem(18) rem(36);
        @include fonts(500, rem(24), rem(34), $sp-fontMedium);
        transition: all 0.3s ease;
        &:hover {
          background-color: colors(sp-hover);
        }
      }
    }
    &-code {
      flex: 1;
      border-radius: rem(5);
      border: dashed rem(1) rgba(colors(sp-primary), 1);
      padding: rem(20);
      background-color: rgba(colors(sp-white), 0.05);
      @include flex(flex, row wrap, space-between, center, null, null);
      &-text {
        @include fonts(400, rem(20), rem(24), $sp-fontRegular);
        color: rgba(colors(sp-white), 1);
        text-transform: uppercase;
      }
      &-checkBox {
        display: block;
        input {
          padding: 0;
          height: rem(30);
          width: rem(30);
          margin-bottom: 0;
          display: none;
          cursor: pointer;
          &:checked + label:after {
            content: "";
            display: block;
            position: absolute;
            top: 6px;
            left: 12px;
            width: 6px;
            height: 14px;
            border: solid 1px rgba(colors(sp-primary), 1);
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
        label {
          position: relative;
          cursor: pointer;
          &:before {
            content: "";
            -webkit-appearance: none;
            background-color: transparent;
            border: solid 1px rgba(colors(sp-primary), 1);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            width: rem(30);
            height: rem(30);
            border-radius: rem(5);
          }
        }
      }
    }
  }
  .sp-nft-card {
    background-color: rgba(colors(sp-white), 0.05);
    border-radius: rem(10);
    padding: rem(15);
    gap: rem(15);
    @include flex(flex, row wrap, null, null, null, null);
    width: 100%;
    &-image {
      @include flex(flex, row wrap, center, center, null, null);
      border-radius: rem(10);
      width: 100%;
      aspect-ratio: 1 / 1;
      overflow: hidden;
    }
    &-title {
      width: 100%;
      @include fonts(400, rem(22), rem(34), $sp-fontRegular);
      color: rgba(colors(sp-white), 1);
    }
  }
  .sp-giftBox {
    @include flex(flex, row wrap, center, null, null, null);
    width: 100%;
    &-image {
      @include flex(flex, row wrap, center, null, null, null);
      width: 100%;
    }
    &-price {
      @include flex(flex, row wrap, center, null, null, null);
      width: 100%;
      @include fonts(500, rem(24), rem(34), $sp-fontMedium);
      color: colors(sp-white);
      padding: rem(30) 0 rem(20);
    }
    &-claimBtn {
      @include flex(flex, row wrap, center, null, null, null);
      width: 100%;
      padding: rem(18) 0;
      border-radius: rem(5);
      background-color: colors(sp-primary);
      transition: all 0.3s ease;
      color: colors(sp-black);
      @include fonts(500, rem(24), rem(34), $sp-fontBold);
      cursor: pointer;
      &:hover {
        background-color: colors(sp-hover);
      }
    }
  }
}
@media only screen and (max-width: 1536px) {
  .sp-share {
    .sp-voucherBox {
      &-title {
        @include fonts(400, rem(22), rem(30), $sp-fontRegular);
      }
      &-subtitle {
        @include fonts(400, rem(18), rem(24), $sp-fontRegular);
        margin: rem(15) 0 rem(10);
      }
      .sp-voucherUsed {
        @include fonts(400, rem(18), rem(24), $sp-fontRegular);
        margin-top: rem(15);
      }
      &-list {
        .sp-invitefrd {
          padding: rem(15) rem(30);
          @include fonts(500, rem(22), rem(30), $sp-fontMedium);
        }
      }
      &-code {
        padding: rem(15);
        &-text {
          @include fonts(400, rem(18), rem(24), $sp-fontRegular);
        }
      }
    }
    .sp-nft-card {
      &-title {
        @include fonts(400, rem(20), rem(30), $sp-fontRegular);
      }
    }
    .sp-giftBox {
      &-price {
        @include fonts(500, rem(22), rem(30), $sp-fontMedium);
        padding: rem(25) 0 rem(20);
      }
      &-claimBtn {
        padding: rem(15) 0;
        @include fonts(500, rem(22), rem(30), $sp-fontBold);
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .sp-share {
    .sp-voucherBox {
      &-title {
        @include fonts(400, rem(20), rem(28), $sp-fontRegular);
      }
      &-subtitle {
        @include fonts(400, rem(16), rem(22), $sp-fontRegular);
        margin: rem(10) 0 rem(10);
      }
      .sp-voucherUsed {
        @include fonts(400, rem(16), rem(22), $sp-fontRegular);
        margin-top: rem(15);
      }
      &-list {
        .sp-invitefrd {
          padding: rem(12) rem(24);
          @include fonts(500, rem(20), rem(28), $sp-fontMedium);
        }
      }
      &-code {
        padding: rem(10) rem(10);
        &-text {
          @include fonts(400, rem(16), rem(22), $sp-fontRegular);
        }
      }
    }
    .sp-nft-card {
      &-title {
        @include fonts(400, rem(18), rem(28), $sp-fontRegular);
      }
    }
    .sp-giftBox {
      &-price {
        @include fonts(500, rem(20), rem(28), $sp-fontMedium);
        padding: rem(20) 0 rem(15);
      }
      &-claimBtn {
        padding: rem(12) 0;
        @include fonts(500, rem(20), rem(28), $sp-fontBold);
      }
    }
  }
}
