.sp-nftVoucher {
  width: 100%;

  .sp-voucher {
    @include flex(flex, row wrap, null, null, null, null);
    width: 100%;
    padding: rem(30);
    border-radius: rem(10);
    background-color: rgba(colors(sp-white), 0.05);

    &-title {
      @include fonts(500, rem(24), rem(34), $sp-fontMedium);
      color: rgba(colors(sp-white), 1);
      width: 100%;
      margin-bottom: rem(20);
    }
  }

  .nft-collumn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    .separator {
      min-width: 50px;
    }

    img {
      max-width: 75px;
      max-height: 75px;
      height: auto;
    }
  }

  .generate-button {
    background-color: #cf9800;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
  }

}

.sp-claimvoucher {

  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.625rem;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1.875rem;
  box-sizing: border-box;

  .sp-connect-wallet {
    position: relative;
    width: 100%;
    @include flex(flex, row wrap, space-between, null, null, null);

    &-text {
      width: calc(100% - 200px);
      @include fonts(400, rem(24), rem(30), $sp-fontRegular);
      color: rgba(colors(sp-white), 0.5);
    }

    .sp-connectBtn {
      border-radius: rem(5);
      transition: all 0.3s ease;
      height: rem(70);
      @include flex(flex, row wrap, center, center, null, null);
      background-color: colors(sp-primary);
      padding: 0 rem(30);
      outline: none;
      @include fonts(500, rem(20), rem(28), $sp-fontMedium);
      border: none;

      &:hover {
        background-color: colors(sp-hover);
      }
    }
  }
}
