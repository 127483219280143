$sp-fontUltralight: "sp-Ultralight";
$sp-fontBlack: "sp-fontBlack";
$sp-fontHeavy: "sp-fontHeavy";
$sp-fontBold: "sp-fontBold";
$sp-fontMedium: "sp-fontMedium";
$sp-fontRegular: "sp-fontRegular";
$sp-fontLight: "sp-fontLight";
$sp-fontThin: "sp-fontThin";
$colors: (
  sp-white: #ffffff,
  sp-black: #131217,
  sp-red: #ff5b5b,
  sp-primary: #f5c02f,
  sp-hover: #cf9800,
  sp-header: #2b2a2f,
  sp-border: #707070,
  sp-blue: #191023,
);
$jf-primary: #1e2021;
$jf-white: #ffffff;
$text: #1e2021;
$transition: all 0.3s ease;
$space: 15px;

$component-active-color: $jf-white;
$component-active-bg: $jf-primary;

$form-switch-color: rgba($jf-primary, 0.5) !default;
$form-check-input-border: 1px solid rgba($jf-primary, 0.5);
$form-check-input-width: 1.75em;
$form-switch-width: 3em;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 95%,
  xl: 1200px,
  xxl: 1470px,
);
$space: 15px;
