.sp-collection {
  width: 100%;
  @include flex(null, row wrap, null, null, null, null);
  margin-top: rem(50);
  &:last-child {
    margin-bottom: rem(0);
  }
  &-head {
    width: 100%;
    @include flex(flex, row wrap, space-between, center, null, null);
    margin-bottom: rem(20);
    .sp-claimpunks {
      width: 240px;
      height: 61px;
      @include flex(flex, null, center, center, null, null);
      border-radius: 5px;
      border: none;
      outline: none;
      color: rgba(colors(sp-black), 1);
      background-color: rgba(colors(sp-primary), 1);
      @include fonts(500, rem(24), rem(33), $sp-fontMedium);
    }
  }
  &-text {
    width: calc(100% - 250px);
  }
  &-title {
    color: rgba(colors(sp-white), 1);
    @include fonts(500, rem(34), rem(40), $sp-fontMedium);
  }
  &-subtitle {
    color: rgba(colors(sp-white), 0.5);
    @include fonts(400, rem(20), rem(26), $sp-fontRegular);
    margin-top: rem(10);
  }
}
@media only screen and (max-width:1536px){
.sp-collection{
  &-head {
    margin-bottom: rem(15);
    .sp-claimpunks {
      width: 220px;
      height: 60px;
      @include fonts(500, rem(22), rem(30), $sp-fontMedium);
    }
  }
  &-title {
    @include fonts(500, rem(30), rem(38), $sp-fontMedium);
  }
  &-subtitle {
    @include fonts(400, rem(18), rem(24), $sp-fontRegular);
  }
}
}
@media only screen and (max-width:1200px){
.sp-collection{
  &-head {
    margin-bottom: rem(15);
    .sp-claimpunks {
      width: 210px;
      height: 50px;
      @include fonts(500, rem(20), rem(28), $sp-fontMedium);
    }
  }
  &-title {
    @include fonts(500, rem(26), rem(32), $sp-fontMedium);
  }
  &-subtitle {
    @include fonts(400, rem(16), rem(22), $sp-fontRegular);
  }
}
}
@media only screen and (max-width:991px){
.sp-collection{
   margin-top: rem(40);
  &-head {
    .sp-claimpunks {
      width: 210px;
      height: 50px;
      @include fonts(500, rem(18), rem(24), $sp-fontMedium);
    }

  }
    &-text{
      width: 100%;
      margin-bottom: rem(15);
    }
  &-title {
    @include fonts(500, rem(26), rem(32), $sp-fontMedium);
  }
  &-subtitle {
    @include fonts(400, rem(16), rem(22), $sp-fontRegular);
  }
}
}
@media only screen and (max-width:767px){
.sp-collection{
   margin-top: rem(30);
    &-head {
      .sp-claimpunks {
        width: 160px;
        height: 40px;
        @include fonts(500, rem(16), rem(22), $sp-fontMedium);
      }
    }
    &-text{
      width: 100%;
      margin-bottom: rem(15);
    }
    &-title {
      @include fonts(500, rem(22), rem(30), $sp-fontMedium);
    }
    &-subtitle {
      @include fonts(400, rem(16), rem(20), $sp-fontRegular);
    }
  }
}