.sp-footer {
    padding: rem(30) rem(12.5);
    width: 100%;
    &-ul {
        @include flex(flex, row wrap, center, center, null, null);
        gap: 20px;
        &-li {
            &-a {
                @include fonts(400, rem(20), rem(26), $sp-fontRegular);
                color: colors(sp-primary);
                transition: all 0.3s ease;
                &:hover {
                    color: colors(sp-white);
                }
            }
        }
    }
}
@media only screen and (max-width: 1536px) {
    .sp-footer {
        &-ul {
            &-li {
                &-a {
                    @include fonts(400, rem(18), rem(24), $sp-fontRegular);
                }
            }
        }
    }
}
@media only screen and (max-width:1200px){
    .sp-footer {
        padding: rem(20) rem(10);
    &-ul{
        gap: 15px;
        &-li {
                &-a {
                    @include fonts(400, rem(16), rem(22), $sp-fontRegular);
                }
            }
    }
    }

}
@media only screen and (max-width:767px){
    .sp-footer {
        padding: rem(15) rem(5);
    &-ul{
        gap: 10px;
        &-li {
                &-a {
                    @include fonts(400, rem(15), rem(20), $sp-fontRegular);
                }
            }
    }
    }

}
