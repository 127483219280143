.sp-home {
  width: 100%;
  position: relative;
  z-index: 0;
  @include flex(flex, row wrap, flex-start, null, null, null);
  .sp-title {
    @include flex(flex, row wrap, flex-start, null, null, null);
    width: 100%;
    @include fonts(400, rem(24), rem(32), $sp-fontRegular);
    margin-bottom: rem(15);
  }
  .sp-connectwallet {
    height: rem(60);
    @include flex(flex, row wrap, center, center, null, null);
    @include fonts(600, rem(20), rem(28), $sp-fontBold);
    color: colors(sp-black);
    transition: $transition;
    background-color: colors(sp-primary);
    border-radius: rem(5);
    width: 100%;
    &:hover {
      background-color: colors(sp-hover);
    }
  }
  .sp-minttext {
    @include flex(flex, row wrap, null, center, null, null);
    width: 100%;
    height: 100%;
    @include fonts(500, rem(18), rem(24), $sp-fontRegular);
  }
  .sp-selectBox {
    width: 100%;
    border-radius: rem(5);
    background-color: rgba(colors(sp-white), 0.1);
    padding: 0 rem(15);
    margin: rem(30) 0 0;
    height: rem(60);
    @include flex(flex, row wrap, space-between, center, null, null);
    .sp-select {
      @include flex(flex, row wrap, null, null, null, null);
      width: rem(110);
      border-radius: rem(5);
      background-color: rgba(colors(sp-white), 0.1);
      border: none;
      height: rem(40);
      // color: $white;
      padding: 0 rem(10);
      background-position: 90% 50%;
      background-image: url("../../assets/media/icon/downarrow.svg");
      background-repeat: no-repeat;
      appearance: none;
      &.token {
        background-color: colors(sp-primary);
        width: rem(150);
        padding-right: 30px;
      }
    }
    .sp-select-input {
      @include flex(flex, row wrap, flex-end, null, null, null);
      border: none;
      color: rgba(colors(sp-white), 1);
      background: none;
      text-align: right;
      width: calc(100% - 110px);
      @include fonts(400, rem(22), rem(30), $sp-fontRegular);
      &.token {
        width: calc(100% - 150px);
      }
    }
    .css-b62m3t-container {
      width: 110px;
      height: 40px;
      background-color: rgba(colors(sp-white), 0.1);
      display: flex;
      border: none;
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
      .css-1s2u09g-control {
        width: 100%;
        height: 100%;
        border: none;
        background-color: rgba(colors(sp-white), 0.1);
        .css-319lph-ValueContainer {
          width: 24px;

          display: flex;
          padding: 0 8px;
        }
      }
    }
    .css-1pahdxg-control {
      width: 100%;
      background-color: colors(sp-black);
      border: none;
      box-shadow: none;
      color: colors(sp-white);
      .css-2613qy-menu {
        background-color: colors(sp-black);
      }
    }
  }
  // upcoming start
  .sp-upcoming {
    width: 100%;
    margin-top: rem(30);
    &-titlebox {
      @include flex(flex, row wrap, space-between, center null, null);
      width: 100%;
      margin-bottom: rem(20);
      .sp-title {
        width: fit-content;
        margin: 0;
      }
      .sp-slider-control {
        @include flex(flex, row wrap, null, center null, null);
        .sp-pre,
        .sp-next {
          width: rem(35);
          height: rem(35);
          border-radius: 50%;
          background-color: rgba(colors(sp-white), 0.1);
          @include flex(flex, row wrap, center, center null, null);
          transition: $transition;
          cursor: pointer;
          svg {
            transform: rotate(90deg);
          }
          &:hover {
            background-color: colors(sp-primary);
          }
        }
        .sp-next {
          margin-left: rem(15);
          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }
    .sp-upcoming-content {
      width: 100%;
      position: relative;
      @include flex(flex, row wrap, null, null, null, null);
      // border-radius: rem(10);
      // overflow: hidden;
      .sp-imageBox {
        // height: 420px;
        display: flex;
        width: 100%;
        border-radius: rem(10);
        overflow: hidden;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .sp-buynow {
        @include position(absolute, rem(20), null, null, rem(20));
        background-color: rgba(colors(sp-primary), 1);
        width: max-content;
        height: rem(40);
        padding: 0 rem(14);
        @include flex(flex, row wrap, center, center, null, null);
        border-radius: rem(5);
        @include fonts(500, rem(16), rem(20), $sp-fontMedium);
        color: colors(sp-black);
        &:hover {
          background-color: colors(sp-hover);
        }
      }
    }
  }
  // road map start
}
@media only screen and (max-width: 1536px) {
  .sp-home {
    .sp-upcoming-image {
      &-content {
        padding: rem(15);
        margin: 0;
        &-title {
          @include fonts(400, rem(30), rem(36), $sp-fontBold);
        }
        .sp-btn {
          padding: rem(15);
          @include fonts(400, rem(16), rem(20), $sp-fontMedium);
        }
      }
    }
    .sp-minttext {
      @include fonts(500, rem(16), rem(22), $sp-fontRegular);
    }
    .sp-connectwallet {
      @include fonts(500, rem(18), rem(24), $sp-fontBold);
    }
  }
}
@media only screen and (max-width: 991px) {
  .sp-home {
    .sp-upcoming {
      margin-top: rem(30);
    }
  }
}
@media only screen and (max-width: 767px) {
  .sp-home {
    .sp-title {
      @include fonts(400, rem(20), rem(28), $sp-fontRegular);
    }
    .sp-upcoming {
      margin-top: rem(25);
    }
    .sp-upcoming-image {
      &-content {
        &-title {
          @include fonts(400, rem(24), rem(32), $sp-fontBold);
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .sp-home {
    .sp-upcoming {
      margin-top: rem(20);
       .sp-upcoming-content{
      .sp-buynow {
        @include position(absolute, rem(10), null, null, rem(10));
        height: rem(30);
        padding: 0 rem(10);
        border-radius: rem(3);
        @include fonts(500, rem(14), rem(20), $sp-fontMedium);
      }
    }
    }

  }
}
@media only screen and (max-width: 320px) {
  .sp-home {
    .sp-upcoming {
      margin-top: rem(15);
    }
  }
}
