.sp-buyPunks {
  @include flex(flex, column wrap, space-between, null, null, null);
  gap: rem(20);
  width: 100%;
  background-color: rgba(colors(sp-white), 0.1);
  height: 100%;
  border-radius: rem(10);
  padding: rem(30);
  &-heading {
    width: 100%;
    padding: 0 0 rem(20);
    border-bottom: rem(1) solid rgba(colors(sp-white), 0.1);
    &-title {
      @include fonts(500, rem(24), rem(33), $sp-fontMedium);
      display: flex;
      align-items: center;
    }
    &-titleDesc {
      @include fonts(400, rem(18), rem(24), $sp-fontRegular);
      color: rgba(colors(sp-white), 0.5);
      margin: 0;
    }
  }
  &-content {
    flex: 1;
    @include flex(flex, row wrap, null, null, flex-start, null);
    gap: rem(15);
    width: 100%;
  }

  .sp-btn {
    width: max-content;
    border-radius: rem(5);
    @include flex(flex, column, center, center, null, null);
    height: rem(60);
    padding: rem(15) rem(30);
    background-color: colors(sp-primary);
    color: colors(sp-black);
    transition: $transition;
    @include fonts(500, rem(24), rem(33), $sp-fontMedium);
    &:hover {
      background-color: colors(sp-hover);
    }
  }
}
.sp-form-group {
  width: 100%;
  &-label {
    @include fonts(400, rem(18), rem(21), $sp-fontRegular);
    margin-bottom: rem(10);
    color: colors(sp-white);
  }
  .sp-form-control {
    height: rem(50);
    padding: rem(10);
    border-radius: rem(5);
    background-color: rgba(colors(sp-black), 0.5);
    border: rem(1) solid rgba(colors(sp-border), 0.5);
    width: 100%;
    color: colors(sp-white);
    @include fonts(400, rem(18), rem(21), $sp-fontRegular);
  }
  &-wrap {
    @include position(relative, null, null, null, null);
    .sp-coinIcon {
      @include position(absolute, rem(10), null, null, rem(10));
    }
    &.sp-pr {
      .sp-form-control {
        padding: rem(10) rem(80) rem(10) rem(10);
      }
    }
    .sp-form-control {
      padding: rem(10) rem(80) rem(10) rem(50);
    }
    .sp-id-btns {
      @include position(absolute, 0, rem(10), 0, null);
      @include flex(flex, row wrap, null, center, null, null);
      @include fonts(400, rem(18), rem(21), $sp-fontRegular);
      margin: auto;
      gap: rem(5);
      &-item {
        height: rem(30);
        width: rem(30);
        border-radius: rem(5);
        background-color: rgba(colors(sp-black), 0.5);
        border: rem(1) solid rgba(colors(sp-border), 0.5);
        @include flex(flex, row wrap, center, center, null, null);
      }
    }
  }
}
.sp-form-group-info {
  width: 100%;
  p {
    @include flex(flex, row wrap, null, center, null, null);
    @include fonts(400, rem(19), rem(23), $sp-fontRegular);
    gap: rem(10);
    opacity: 0.5;
    margin: 0;
  }
}
@media only screen and (max-width:1536px){
.sp-buyPunks {
  gap: rem(20);
  padding: rem(30);
  &-heading {
    &-title {
      @include fonts(500, rem(22), rem(32), $sp-fontMedium);
    }
    &-titleDesc {
      @include fonts(400, rem(17), rem(22), $sp-fontRegular);
    }
  }
  &-content {
    gap: rem(15);
  }

  .sp-btn {
    height: rem(50);
    padding: rem(15) rem(20);
    @include fonts(500, rem(22), rem(30), $sp-fontMedium);
  }
}


}
@media only screen and (max-width:1200px){
  .sp-buyPunks {
  gap: rem(15);
  padding: rem(20);
  &-heading {
    &-title {
      @include fonts(500, rem(20), rem(30), $sp-fontMedium);
    }
    &-titleDesc {
      @include fonts(400, rem(16), rem(20), $sp-fontRegular);
    }
  }
  &-content {
    gap: rem(15);
  }
  .sp-btn {
    height: rem(50);
    padding: rem(15) rem(20);
    @include fonts(500, rem(20), rem(28), $sp-fontMedium);
  }
}
}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}