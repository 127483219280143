.sp-roadmap {
  .sp-card-header-title{
    @include flex(flex, row wrap, null, center,null,null);
  }
  .sp-reverse{
      margin: rem(30) 0;
    }
  .sp-roadmap-section-icon{
    @include flex(flex, row wrap, center, center,null,null);
    width: 100%;
    padding: rem(30);
  }
  .sp-section-text-box{
      @include flex(flex, row wrap, null, center,null,null);
      height: 100%;
      width: 100%;
    .sp-section-content{
      @include flex(flex, row wrap, null, center,null,null);
      width: 100%;
      .sp-card-subtitle{
         width: 100%;
         @include flex(flex, nowrap, null, center,null,null);
        color: colors(sp-white);
        margin-top: rem(20);
        @include fonts(400, rem(22), rem(34), $sp-fontRegular);
        span{
         color: colors(sp-primary);
         margin: 0 rem(7);
        }
      }
      &-title{
        width: 100%;
         @include flex(flex, nowrap, null, center,null,null);
        color: colors(sp-white);
        @include fonts(400, rem(34), rem(40), $sp-fontRegular);
        span{
          width: rem(60);
          margin-right: rem(20);
        }
      }
      &-dis{
        @include fonts(400, rem(22), rem(34), $sp-fontRegular);
        color: rgba(colors(sp-white), 0.5);
        margin: rem(20) 0 0;
        width: 100%;
      }
    }
  }
  .sp-syberpunks {
    width: 100%;
    margin-top: rem(50);
    .sp-card-header{
      border-bottom: none;
      margin: 0;
    }
    .sp-nftBox {
    width: 100%;
    padding: rem(15);
    background-color: rgba(colors(sp-white), 0.05);
    border-radius: rem(10);
    &-image {
      width: 100%;
      aspect-ratio: 1 / 1;
      @include flex(flex, null, null, null, null, null);
      border-radius: rem(10);
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &-content {
      @include flex(flex, row wrap, space-between, center, null, null);
      margin-top: rem(15);
    }
    &-types {
      @include flex(flex, column, null, null, null, null);
      &-title {
        color: rgba(colors(sp-white), 0.5);
        @include fonts(400, rem(16), rem(24), $sp-fontRegular);
      }
      &-subtitle {
        color: rgba(colors(sp-white), 1);
        margin-top: rem(10);
        @include fonts(400, rem(16), rem(24), $sp-fontMedium);
      }
    }
    &-price {
      @include flex(flex, column, null, null, null, null);
      &-title {
        color: rgba(colors(sp-white), 0.5);
        @include fonts(400, rem(16), rem(24), $sp-fontRegular);
        text-align: right;
      }
      &-subtitle {
        color: rgba(colors(sp-white), 1);
        margin-top: rem(10);
        @include fonts(400, rem(16), rem(24), $sp-fontMedium);
        display: flex;
        .sp-icon {
          width: 24px;
          height: 24px;
          @include flex(flex, row wrap, center, center, null, null);
          margin-right: rem(10);
        }
      }
    }
  }
  }
  .sp-boredape{
    @extend .sp-syberpunks;
  }
}

@media only screen and (max-width:1536px){
  .sp-roadmap {
    .sp-roadmap-section-icon{
      padding: rem(25);
    }
    .sp-section-text-box{
      .sp-section-content{
        .sp-card-subtitle{
          margin-top: rem(15);
          @include fonts(400, rem(20), rem(30), $sp-fontRegular);
        }
        &-title{
          @include fonts(400, rem(32), rem(40), $sp-fontRegular);
          span{
            width: rem(55);
            margin-right: rem(15);
          }
        }
        &-dis{
          @include fonts(400, rem(20), rem(30), $sp-fontRegular);
          margin: rem(15) 0 0;
        }
      }
    }
    .sp-syberpunks {
      margin-top: rem(40);
    }
    .sp-boredape{
      margin-top: rem(40);
    }
  }
}
@media only screen and (max-width:1200px){
  .sp-roadmap {
    .sp-roadmap-section-icon{
      padding: rem(20);
    }
    .sp-section-text-box{
      .sp-section-content{
        .sp-card-subtitle{
          margin-top: rem(10);
          @include fonts(400, rem(18), rem(26), $sp-fontRegular);
        }
        &-title{
          @include fonts(400, rem(30), rem(38), $sp-fontRegular);
          span{
            width: rem(50);
            margin-right: rem(10);
          }
        }
        &-dis{
          @include fonts(400, rem(18), rem(26), $sp-fontRegular);
          margin: rem(15) 0 0;
        }
      }
    }
    .sp-syberpunks {
      margin-top: rem(30);
    }
    .sp-boredape{
      margin-top: rem(30);
    }
  }
}
@media only screen and (max-width:991px){
  .sp-roadmap {
    .sp-reverse{
      flex-direction: column-reverse;
      margin-top: rem(30);
      .sp-roadmap-section-icon{
        width: 50%;
        margin: 0 auto;
        padding: rem(30);
      }
    }
    .sp-roadmap-section-icon{
      padding: rem(15);
      width: 50%;
      margin: 0 auto;
    }
    .sp-section-text-box{
      .sp-section-content{
        .sp-card-subtitle{
          margin-top: rem(10);
          @include fonts(400, rem(16), rem(24), $sp-fontRegular);
        }
        &-title{
          @include fonts(400, rem(24), rem(30), $sp-fontRegular);
        }
        &-dis{
          @include fonts(400, rem(16), rem(24), $sp-fontRegular);
          margin: rem(10) 0 0;
        }
      }
    }
    .sp-syberpunks {
      margin-top: rem(30);
    }
    .sp-boredape{
      margin-top: rem(30);
    }
  }
}
@media only screen and (max-width:767px){
  .sp-roadmap {
   .sp-reverse{

      .sp-roadmap-section-icon{
        padding: rem(10);
      }
    }
    .sp-section-text-box{
      .sp-section-content{
        .sp-card-subtitle{
          margin-top: rem(10);
          @include fonts(400, rem(15), rem(22), $sp-fontRegular);
        }
        &-title{
          @include fonts(400, rem(20), rem(26), $sp-fontRegular);
        }
        &-dis{
          @include fonts(400, rem(15), rem(22), $sp-fontRegular);
          margin: rem(10) 0 0;
        }
      }
    }
    .sp-syberpunks {
      margin-top: rem(20);
    }
    .sp-boredape{
      margin-top: rem(20);
    }
  }
}
