.switch {
  display: inline-block;
  span {
    position: relative;
    width: 58px;
    height: 32px;
    float: left;
    input {
      display: none;
      &:checked + .slider {
        background-color: colors(sp-primary);
      }
      &:checked + .slider:before {
        transform: translateX(26px);
      }
      &:focus + .slider {
        box-shadow: 0 0 1px colors(sp-primary);
      }
    }
  }
  label {
    line-height: 32px;
    margin-left: 12px;
    cursor: pointer;
    @include fonts(400, rem(16), rem(20), $sp-fontRegular);
  }
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border: 0;
  outline: none;
  background-color: rgba(colors(sp-white), 0.5);
  transition: 0.4s;
  border-radius: 32px;
  cursor: pointer;
  &:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 5px;
    bottom: 5px;
    background-color: rgba(colors(sp-white), 1);
    transition: 0.4s;
    border-radius: 50%;
  }
}
