.sp-generateVoucher {
  width: 100%;
  .sp-voucher {
    @include flex(flex, row wrap, null, null, null, null);
    width: 100%;
    padding: rem(30);
    border-radius: rem(10);
    background-color: rgba(colors(sp-white), 0.05);
    &-title {
      @include fonts(500, rem(24), rem(34), $sp-fontMedium);
      color: rgba(colors(sp-white), 1);
      width: 100%;
      margin-bottom: rem(20);
    }
  }
}
// table css
.sp-delete-code {
  color: rgba(colors(sp-black), 1);
  border-radius: rem(5);
  background-color: colors(sp-primary);
  @include flex(flex, row wrap, center, center, null, null);
  padding: rem(10) rem(25);
  @include fonts(400, rem(16), rem(24), $sp-fontRegular);
  outline: none;
  border: none;
  transition: all 0.3s ease;
  &:hover {
    background-color: colors(sp-hover);
  }
}
.sp-inputBox {
  width: 100%;
  border-radius: rem(10);
  background-color: rgba(colors(sp-white), 0.1);
  padding: rem(30);
  gap: rem(20);
  .sp-inputlabel {
    @include flex(flex, row wrap, null, null, null, null);
    color: rgba(colors(sp-white), 1);
    @include fonts(500, rem(24), rem(34), $sp-fontRegular);
    margin-bottom: rem(5);
  }
  .sp-label-intro {
    @include flex(flex, row wrap, null, null, null, null);
    color: rgba(colors(sp-white), 0.5);
    @include fonts(400, rem(18), rem(24), $sp-fontRegular);
    margin-bottom: rem(15);
  }
  .sp-inputlabel-inputBox {
    width: 100%;
    @include flex(flex, row wrap, null, null, null, null);
    gap: rem(15);
    .sp-admin-input {
      flex: 1;
      background-color: rgba(colors(sp-black), 0.5);
      border-radius: rem(5);
      border: none;
      outline: none;
      color: rgba(colors(sp-white), 1);
      @include fonts(400, rem(22), rem(26), $sp-fontRegular);
      padding: 0 rem(20);
      height: rem(70);
      &::placeholder {
        color: rgba(colors(sp-white), 0.5);
      }
    }
    .sp-admin-inputBox {
      flex: 1;
      @include flex(flex, row wrap, space-between, center, null, null);
      width: calc(100% - rem(291));
      background-color: rgba(colors(sp-black), 0.5);
      border-radius: rem(5);
      border-radius: rem(5);
      padding: 0 rem(20);
      @include fonts(400, rem(22), rem(26), $sp-fontRegular);
      .sp-admin-input {
        width: 70%;
        margin: 0;
        border: none;
        outline: none;
        color: rgba(colors(sp-white), 1);
        padding: 0;
        background-color: transparent;
        &::placeholder {
          color: rgba(colors(sp-white), 0.5);
        }
      }
      .sp-value-title {
        @include fonts(400, rem(22), rem(26), $sp-fontRegular);
        text-align: end;
      }
    }
  }
}
.sp-admin-btn {
  padding: 0 rem(20);
  height: rem(70);
  @include flex(flex, row wrap, center, center, null, null);
  border-radius: rem(5);
  background-color: rgba(colors(sp-primary), 1);
  color: colors(sp-black);
  @include fonts(500, rem(24), rem(34), $sp-fontMedium);
  border: none;
  outline: none;
  transition: all 0.3s ease;
  &:hover {
    background-color: colors(sp-hover);
  }
}
@media only screen and (max-width: 1536px) {
  .sp-generateVoucher {
    &-box {
      padding: rem(20);
    }
  }
}
