.sp-admin {
	width: 100%;
	.sp-card {
		margin-bottom: rem(30);
		&:last-child {
			margin-bottom: 0;
		}
	}
	.sp-admin-btn {
		padding: 0 rem(20);
		height: rem(70);
		@include flex(flex, row wrap, center, center, null, null);
		border-radius: rem(5);
		background-color: rgba(colors(sp-primary), 1);
		color: colors(sp-black);
		@include fonts(500, rem(24), rem(34), $sp-fontMedium);
		border: none;
		outline: none;
		transition: all 0.3s ease;
		&:hover {
			background-color: colors(sp-hover);
		}
	}
	.sp-inputBox {
		width: 100%;
		border-radius: rem(10);
		background-color: rgba(colors(sp-white), 0.1);
		padding: rem(30);
		gap: rem(20);
		.sp-inputlabel {
			@include flex(flex, row wrap, null, null, null, null);
			color: rgba(colors(sp-white), 1);
			@include fonts(500, rem(24), rem(34), $sp-fontRegular);
			margin-bottom: rem(5);
		}
		.sp-label-intro {
			@include flex(flex, row wrap, null, null, null, null);
			color: rgba(colors(sp-white), 0.5);
			@include fonts(400, rem(18), rem(24), $sp-fontRegular);
			margin-bottom: rem(15);
		}
		.sp-inputlabel-inputBox {
			width: 100%;
			@include flex(flex, row wrap, null, null, null, null);
			gap: rem(15);
			.sp-admin-input {
				flex: 1;
				background-color: rgba(colors(sp-black), 0.5);
				border-radius: rem(5);
				border: none;
				outline: none;
				color: rgba(colors(sp-white), 1);
				@include fonts(400, rem(22), rem(26), $sp-fontRegular);
				padding: 0 rem(20);
				height: rem(70);
				&::placeholder {
					color: rgba(colors(sp-white), 0.5);
				}
			}
			.sp-admin-inputBox {
				flex: 1;
				@include flex(flex, row wrap, space-between, center, null, null);
				width: calc(100% - rem(291));
				background-color: rgba(colors(sp-black), 0.5);
				border-radius: rem(5);
				border-radius: rem(5);
				padding: 0 rem(20);
				@include fonts(400, rem(22), rem(26), $sp-fontRegular);
				.sp-admin-input {
					width: 70%;
					margin: 0;
					border: none;
					outline: none;
					color: rgba(colors(sp-white), 1);
					padding: 0;
					background-color: transparent;
					&::placeholder {
						color: rgba(colors(sp-white), 0.5);
					}
				}
				.sp-value-title {
					@include fonts(400, rem(22), rem(26), $sp-fontRegular);
					text-align: end;
				}
			}
		}
	}
	.sp-mint-setting {
		@extend .sp-inputBox;
		.sp-card-header {
			margin-bottom: 0;
			border-bottom: none;
		}
		&-label {
			@include flex(flex, row wrap, null, null, null, null);
			color: rgba(colors(sp-white), 1);
			@include fonts(500, rem(24), rem(34), $sp-fontRegular);
			margin-bottom: rem(5);
		}
		&-input {
			background-color: rgba(colors(sp-black), 0.5);
			border-radius: rem(5);
			border: none;
			outline: none;
			color: rgba(colors(sp-white), 1);
			@include fonts(400, rem(22), rem(26), $sp-fontRegular);
			padding: 0 rem(20);
			width: 100%;
			height: rem(70);
		}
	}
}
@media only screen and (max-width: 1536px) {
	.sp-admin {
		.sp-card {
			margin-bottom: rem(25);
		}
		.sp-admin-btn {
			padding: 0 rem(15);
			height: rem(70);
			@include fonts(500, rem(22), rem(30), $sp-fontMedium);
		}
		.sp-inputBox {
			padding: rem(25);
			gap: rem(15);
			.sp-inputlabel {
				@include fonts(500, rem(22), rem(30), $sp-fontRegular);
				margin-bottom: rem(15);
			}
			.sp-inputlabel-inputBox {
				gap: rem(15);
				.sp-admin-input {
					@include fonts(400, rem(20), rem(24), $sp-fontRegular);
					padding: 0 rem(15);
					height: rem(70);
				}
				.sp-admin-inputBox {
					flex: 1;
					padding: 0 rem(15);
					@include fonts(400, rem(20), rem(24), $sp-fontRegular);
				}
				.sp-value-title {
					@include fonts(400, rem(20), rem(24), $sp-fontRegular);
				}
			}
		}
		.sp-mint-setting {
			&-label {
				@include fonts(500, rem(22), rem(30), $sp-fontRegular);
				margin-bottom: rem(15);
			}
			&-input {
				@include fonts(400, rem(20), rem(24), $sp-fontRegular);
				padding: 0 rem(15);
			}
		}
	}
}
@media only screen and (max-width: 1200px) {
	.sp-admin {
		.sp-card {
			margin-bottom: rem(20);
		}
		.sp-admin-btn {
			padding: 0 rem(15);
			height: rem(70);
			@include fonts(500, rem(20), rem(28), $sp-fontMedium);
		}
		.sp-inputBox {
			padding: rem(20);
			gap: rem(15);
			.sp-inputlabel {
				@include fonts(500, rem(20), rem(28), $sp-fontRegular);
				margin-bottom: rem(15);
			}
			.sp-inputlabel-inputBox {
				gap: rem(15);
				.sp-admin-input {
					@include fonts(400, rem(18), rem(24), $sp-fontRegular);
					padding: 0 rem(15);
					height: rem(70);
				}
				.sp-admin-inputBox {
					padding: 0 rem(15);
					@include fonts(400, rem(18), rem(24), $sp-fontRegular);
				}
				.sp-value-title {
					@include fonts(400, rem(18), rem(24), $sp-fontRegular);
				}
			}
		}
		.sp-mint-setting {
			&-label {
				@include fonts(500, rem(20), rem(28), $sp-fontRegular);
				margin-bottom: rem(15);
			}
			&-input {
				@include fonts(400, rem(18), rem(24), $sp-fontRegular);
				padding: 0 rem(15);
			}
		}
	}
}
@media only screen and (max-width: 991px) {
	.sp-admin {
		.sp-card {
			margin-bottom: rem(15);
		}
		.sp-admin-btn {
			padding: 0 rem(15);
			height: rem(60);
			@include fonts(500, rem(18), rem(24), $sp-fontMedium);
		}
		.sp-inputBox {
			padding: rem(15);
			gap: rem(15);
			.sp-inputlabel {
				@include fonts(500, rem(18), rem(24), $sp-fontRegular);
				margin-bottom: rem(15);
			}
			.sp-inputlabel-inputBox {
				gap: rem(15);
				.sp-admin-input {
					@include fonts(400, rem(16), rem(22), $sp-fontRegular);
					padding: 0 rem(15);
					height: rem(60);
				}
				.sp-admin-inputBox {
					padding: 0 rem(15);
					@include fonts(400, rem(16), rem(22), $sp-fontRegular);
				}
				.sp-value-title {
					@include fonts(400, rem(16), rem(22), $sp-fontRegular);
				}
			}
		}
		.sp-mint-setting {
			&-label {
				@include fonts(500, rem(18), rem(24), $sp-fontRegular);
				margin-bottom: rem(15);
			}
			&-input {
				@include fonts(400, rem(16), rem(22), $sp-fontRegular);
				padding: 0 rem(15);
				height: rem(60);
			}
		}
	}
}
